import React from "react";
// import Img from "./img";
// import Link from "@/components/link";

const Terms = () => {
  // const { image, date, author, commentCount, title, url, text } = data;

  // const style = { textAlign: "justify" };

  return (
    <div
      // className="blog_share_box"
      style={{ padding: "80px 50px" }}
    >
      <div className="blog_share_details">
        <h1>Terms & Conditions of Nifixeci</h1>
        <p>
          Welcome to the Nifixeci website. Access to and use of this site is
          subject to the following Terms and Conditions. Please take a minute to
          review these. If you do not agree with these terms, please do not use
          our website.
        </p>
        <br />
        <h4>1. Introduction</h4>
        <p>
          These Terms and Conditions govern your use of the Nifixeci website,
          herein referred to as the "Site", and the services provided by
          Nifixeci, a Hong Kong-based pest control business. If you have any
          questions or concerns, please don't hesitate to contact us at
          info@nifixeci.com.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>2. Use of the Site</h4>
        <li>
          Eligibility: By using this Site, you agree that you are at least 18
          years of age or the age of majority in your State/Country of
          residence, or that you are browsing the site under the supervision of
          a parent or guardian.
        </li>
        <li>
          License: We hereby grant you a limited, non-exclusive,
          non-transferable license to access and use the Site for personal and
          non-commercial purposes only.
        </li>
      </div>
      <div className="blog_share_details">
        <h4>3. Intellectual Property</h4>
        <p>
          All information, text, images, logos, graphics, sounds, and other
          materials on this Website belong to Nifixeci and its information
          providers and are protected by copyright, trademark, and other
          proprietary rights. You may not reproduce, distribute, display, or
          create derivative works based on any information, text, images, logos,
          graphics, sounds, and other materials on this Website, in whole or in
          part, without our prior written consent.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>4. Information About the Services</h4>
        <li>
          Service Description: Nifixeci provides various kinds of pest control
          services in Hong Kong. The services include but shall not be limited
          to the following: cockroach control; mosquito control; bed bug
          control; rodent control; snake prevention; termite control.
        </li>
        <li>
          Availability: Services are subject to availability, and may be
          available only at particular locations. We may also, without prior
          notice, change or discontinue any service at any time.
        </li>
      </div>
      <div className="blog_share_details">
        <h4>5. Booking and Payment</h4>
        <li>
          Booking: You shall provide correct and complete information when
          booking our Service. We shall inform you of confirmation of booking by
          email.
        </li>
        <li>
          Payment: The payment for availing the Services shall be made in
          accordance with terms prevailing at the time of booking. We accept
          various modes of payment as mentioned on our Site.
        </li>
      </div>
      <div className="blog_share_details">
        <h4>6. Your Responsibilities</h4>
        <li>
          Accuracy: You agree to provide correct and complete information while
          using the Site or while booking Service.
        </li>
        <li>
          Prohibited Use: You shall not use the Site for any unlawful purpose,
          or in any manner which could damage, disable, overburden, or impair
          the Site.
        </li>
      </div>
      <div className="blog_share_details">
        <h4>7. Limitation of Liability</h4>
        <p>
          Nifixeci Disclaimer. We do not warrant or represent that the
          information on this Site is accurate or complete. To the fullest
          extent permitted by applicable law, we disclaim all warranties.
          Nifixeci will not be liable for any damages of any kind arising from
          your use of this Site or the services offered, except to the extent
          required by applicable law.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>8. Indemnity</h4>
        <p>
          You hereby agree to defend, indemnify, and hold harmless Nifixeci, its
          officers, directors, employees, and agents from and against any
          claims, liabilities, damages, losses, or expenses arising from your
          use of the Site and any breach of these Terms and Conditions.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>9. Privacy Policy</h4>
        <p>
          Your use of the Site is also subject to our Privacy Policy. The
          dedicated document outlines how we collect, use, and secure your
          personal details. Please read our full Privacy Policy for more
          information.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>10. Modification of the Terms and Conditions</h4>
        <p>
          Nifixeci reserves the right to modify these Conditions of Use of the
          Websites at any time and without prior notice. Such modifications
          shall be understood as accepted by the mere access to the Websites.
          Changes will take effect from the date of their publication on the
          Websites. Your continued use of the Website subsequent to such
          publication will be deemed your acceptance of the modified Terms and
          Conditions of Use.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>11. Governing Law</h4>
        <p>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of Hong Kong. Any dispute arising out of or
          in connection with these Terms and Conditions, the parties submit to
          the exclusive jurisdiction of the courts of Hong Kong.
        </p>
      </div>
      <div className="blog_share_details">
        <h4>12. Contact Us</h4>
        <p>
          If you have any questions about this Agreement or our service, please
          contact us at info@nifixeci.com.
        </p>
      </div>
      <div className="blog_share_details">
        <span className="comment_author">
          {/* by <Link href={url}>{author}</Link> */}
          {/* -{" "}
          <Link href={url}>{commentCount} Comments</Link> */}
        </span>
        <h1>{/* <Link href={url}>{title}</Link> */}</h1>
        {/* <p>{text}</p> */}
      </div>
    </div>
  );
};

export default Terms;
