import React from "react";
import Layout from "@/components/layout";
import HeaderOne from "@/components/header-one";
import MenuContextProvider from "@/context/menu-context";
import PageHeader from "@/components/page-header";
import Footer from "@/components/footer";
// import AboutFour from "@/components/about-four";
// import VideoOne from "@/components/video-one";
// import TestimonialsOne from "@/components/testimonials-one";
// import TeamOne from "@/components/team-one";
import Terms from "@/components/terms";
// import AboutTwo from "@/components/about-two";
import { Helmet as Head } from "react-helmet";

const About = () => {
  return (
    <MenuContextProvider>
      <Layout PageTitle="About Page">
        <Head>
          <title>Terms and Conditions| Nifixeci</title>
          <meta
            name="description"
            content="You’ll discover all the details regarding our terms of using pest
            extermination solutions through this document."
          />
        </Head>
        <HeaderOne />
        <PageHeader title="Terms & Conditions" name="Terms & Conditions" />
        {/* <AboutFour /> */}
        {/* <VideoOne /> */}
        <Terms />
        {/* <TestimonialsOne /> */}
        {/* <TeamOne extraClassName="section_border" /> */}
        {/* <SponsorOne /> */}
        <Footer />
      </Layout>
    </MenuContextProvider>
  );
};

export default About;
